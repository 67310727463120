import React, { useState } from "react";
import Swal from "sweetalert2";
import { URL_SERVICIOS } from "../services/UrlWebServis";
import config from "../config";
import 'animate.css';

const FormularioParticipante = () => {
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [validado, setValidado] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [links, setLinks] = useState({ certificado: '', descuento: '' });

  const [btnCertificado, setBtnCertificado] = useState(false);
  const [btnDescuento, setBtnDescuento] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que los campos no estén vacíos
    if (!nombre || !apellido || !email) {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son obligatorios.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    const datos = {
      nombres: `${nombre} ${apellido}`, // Unir nombre y apellido
      email,
    };

    try {
      // Realizar la petición POST para validar/registrar
      const response = await fetch(URL_SERVICIOS + "/api/participantes/validar", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datos),
      });

      const result = await response.json();

    

      if (!result.error) {
        // Validado correctamente, mostrar mensaje de éxito
        setEnviado(true);
        setValidado(true);
        console.log(result.data.certificado_link)
        console.log(result.data.descuento_link)
        setLinks({
            certificado: result.data.certificado_link,
            descuento: result.data.descuento_link,
        });

      } else {
        // Error en la validación, mostrar mensaje de error
        setEnviado(true);
        setValidado(false);
        setMensaje(result.message || "Ocurrió un error durante la validación.");
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al procesar tu solicitud. Inténtalo más tarde.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };


  const descargarCertificado = () => {
        window.open(links.certificado, '_blank')
        setBtnCertificado(true)
  }

  const descargarDescuento = () => {
        window.open(links.descuento, '_blank')
        setBtnDescuento(true)
  }

  return (
    <>
    <header id="header" className="headerazul fixed-top d-flex align-items-center">
             
            <div className="d-flex align-items-center justify-content-between ">
            <a href="/home" className="logo d-flex align-items-center animate__animated animate__flipInY">
            <img src="/assets/img/logo_blanco_nat.png" alt="" style={{color:'#fff'}} />
            
          </a>
          
        </div>     
    </header>
        <main id="mainform" className="">
        
    <div className="container" style={{maxWidth:'800px'}}>
    <section className='section dashboard'>
    <div className="card animate__animated animate__pulse">
    <div className="card-body pb-0"  style={{ marginTop: '70px' }}>

    {!enviado && 
    <>
    <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <img src="/assets/img/logo_national.png" width={300} />                    
                </div>
        <h5 className="card-title"  style={{ textAlign: 'center' }}>¡Muchas gracias por haber asistido a nuestro seminario!<br />
        Para generar su certificado de participación, por favor complete<br />
        Los siguientes campos para validad su información.<br />
        </h5>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Nombre:</label>
            <input
              type="text"
              className="form-control"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Apellido:</label>
            <input
              type="text"
              className="form-control"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Correo Electrónico:</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="col-md-12"  style={{ textAlign: 'center'}}>
          <button type="submit" className="btn btn-danger mb-3">
            Pulse aquí para validar su información
          </button>
          </div>
        </form>
        </>
        
        }

        {validado && enviado && (
            <div className="row">
                <div className="col-md-12" style={{ textAlign: 'center', marginTop: '70px' }}>
                    <img src="/assets/img/logo_national.png" width={300} />                    
                </div>
                <div className="col-md-12">
                    <h5 className="card-title" style={{ textAlign: 'center', marginTop: '30px' }}>
                        Su información ha sido validada correctamente.<br />
                        Por favor, pulse el botón a continuación para <br />
                        generar su certificado de participación.<br />
                    </h5>
                    <div style={{ textAlign: 'center', marginTop: '10px', color: '' }}>
                        Para poder visualizar su certificado, es necesario <br />
                        contar con un programa que permita la lectura de archivos en formato PDF.
                    </div>
                    <div className="alert-danger txtsede" style={{ textAlign: 'center', marginTop: '10px' }}>
                        ¡No olvide descargar su descuento!
                    </div>
                </div>
                <div className="col-md-12" style={{ textAlign: 'center', marginTop: '30px' }}>
                    <button type="button" className="btn btn-danger-custom mb-3"  disabled={btnCertificado || !links.certificado}  onClick={() => descargarCertificado()}>
                    {btnCertificado ? (
                            <>YA SE DESCARGÓ EL CERTIFICADO</>
                        ) : links.certificado ? (
                            <>PULSE AQUÍ PARA GENERAR SU CERTIFICADO</>
                        ) : (
                            <>YA SE DESCARGÓ EL CERTIFICADO</>
                        )}
                    </button>
                </div>
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <button type="button" className="btn btn-primary-custom mb-3" disabled={btnDescuento || !links.descuento} onClick={() => descargarDescuento()}>
                    {btnDescuento ? (
                            <>YA SE DESCARGÓ EL DESCUENTO</>
                        ) : links.descuento ? (
                            <>PULSE AQUÍ PARA DESCARGAR SU DESCUENTO</>
                        ) : (
                            <>YA SE DESCARGÓ EL DESCUENTO</>
                        )}
                    </button>
                </div>
            </div>
        )}

        {!validado && enviado && (
            <div className="row">
                <div className="col-md-12" style={{ textAlign: 'center', marginTop: '70px' }}>
                    <img src="/assets/img/logo_national.png" width={300} />                    
                </div>
                <div className="col-md-12" style={{ textAlign: 'center', marginTop: '30px' }}>
                    <h5 className="card-title" style={{ textAlign: 'center' }}>
                        Lo sentimos, no pudimos validar su <br />
                        Información. Por favor, verifique los datos <br />
                        Ingresados y vuelva a intentarlo. <br />
                    </h5>
                </div>
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <button type="button" className="btn btn-danger mb-3" onClick={() => window.location.reload()}>
                        Pulse aquí volver a consultar
                    </button>
                </div>
            </div>
        )}

    </div>
    </div>
    </section>
    </div>
    </main>
    
    </>
  );
};

export default FormularioParticipante;
