import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { SessionContext } from './SessionContext';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import FormularioParticipante from './pages/FormularioParticipante'; // Importa la nueva página pública

function Rutas() {
    const { user } = useContext(SessionContext);
  
    return (
        <Router>
            <Routes>
                {/* Ruta de inicio (Login o Redirección) */}
                <Route 
                    path="/" 
                    element={!user ? <LoginPage /> : <Navigate to="/home" />} 
                />
                
                {/* Ruta protegida para Home */}
                <Route 
                    path="/home/*" 
                    element={user ? <HomePage /> : <Navigate to="/" />} 
                />
                
                {/* Ruta pública para el formulario de participantes */}
                <Route 
                    path="/validar-participante" 
                    element={<FormularioParticipante />} 
                />

                {/* Ruta no encontrada */}
                <Route 
                    path="*" 
                    element={<h1>Página no encontrada</h1>} 
                />
            </Routes>
        </Router>
    );
}

export default Rutas;
