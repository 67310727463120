import React, { useContext, useEffect, useState } from 'react'
import { useHttpRequest } from '../services/useHttpRequest';
import { SessionContext } from '../SessionContext';
import Swal from 'sweetalert2';
import { URL_SERVICIOS } from '../services/UrlWebServis';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const EnviarEmail = () => {

    const [ListadoSeminarios, setListadoSeminarios] = useState([])
    const [ListadoParticipantes, setListadoParticipantes] = useState([])
    const [selectedSeminario, setSelectedSeminario] = useState("");
    const [estadoEnvio, setEstadoEnvio] = useState({});
    const [totales, setTotales] = useState({});
    const [Loading, setLoading] = useState(false);
    const [btnEnviando, setbtnEnviando] = useState(false);
    const { token } = useContext(SessionContext); 

    const HttpRequest = useHttpRequest();
    
    const fetchSeminarios = async () => {
        try {
            const response = await HttpRequest('api/seminarios', 'GET');
            setListadoSeminarios(response);

           

        } catch (error) {
            toast.error('Error al obtener los seminarios: ' + error);            
        }
    };

    const fetchParticipantes = async () => {
        
        if (!selectedSeminario)
            return;

        try {
            setLoading(true)
            const response = await HttpRequest('api/participantes/seminario/' + selectedSeminario, 'GET');
            const datos = response.data;
            setListadoParticipantes(datos);
            setLoading(false)

               // Calcular totales
               const totalEnviados = datos.filter(part => parseInt(part.enviado) === 1).length;
               const totalDescargados = datos.filter(part => parseInt(part.descargado) === 1).length;
               const totalDescuentos = datos.filter(part => parseInt(part.descuento) === 1).length;
   
               // Actualizar estado con los totales
               setTotales({
                   enviados: totalEnviados,
                   descargados: totalDescargados,
                   descuentos: totalDescuentos,
               });

        } catch (error) {
            toast.error('Error al obtener los seminarios: ' + error); 
        }
    };



    useEffect(() => {
        fetchSeminarios();
    }, []);

    useEffect(() => {
        fetchParticipantes();
    }, [selectedSeminario]);


    const confirmarEnvio = () => {
        Swal.fire({
                  title: '¿Estás seguro?',
                  text: `¡No podrás revertir esto! ¿Estás seguro de enviar los emails?`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sí, Envarlos',
              }).then(async (result) => {
                    if (result.isConfirmed) {
                        enviarCorreos()
                    }
              })

    } 

    const enviarCorreos = async () => {
        setLoading(true);
        
        
        try {
            const response = await fetch(
                 `${URL_SERVICIOS}api/certificados/generar/${selectedSeminario}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

                const result = await response.json();

                if (result.error === false) {

                   /*  // Actualizar el estado de cada participante en la tabla
                    const nuevosEstados = {};
                    result.data.forEach((item) => {
                        nuevosEstados[item.id_participante] = item.enviado ? 'Enviado' : 'Error';
                    });

                    setEstadoEnvio((prev) => ({
                        ...prev,
                        ...nuevosEstados,
                    })); */

                    fetchParticipantes();

                    
                     Swal.fire({
                                      title: 'Guardar',
                                      text: 'Correos enviados correctamente.',
                                      icon: 'success',
                                      confirmButtonText: 'Aceptar',
                                  });
                } else {
                    toast.error(`Error: ${result.message}`);
                }
            } catch (error) {
                //setMensaje('Error al enviar los correos. Por favor, intenta de nuevo.');
                toast.error('Error al enviar los correos:', error);
            } finally {
                setLoading(false);
            }
    };


  return (
    <>
    <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Enviar E-mail</div>    
                <div className="card-body pb-0">

                <div className='row mb-2'>
                        <div className='col-md-5'>
                        <strong>Seminario: </strong>
                        <select className="form-select form-select-sm estilocampo" onChange={(e) => setSelectedSeminario(e.target.value)} value={selectedSeminario || ''}>
                            <option value="" disabled>Selecciona un seminario</option>
                            {ListadoSeminarios.map((seminario) => (
                                <option key={seminario.id_seminario} value={seminario.id_seminario}>
                                    {seminario.nombre_seminario}
                                </option>
                            ))}
                        </select>
                        </div>
                        <div className='col-md-3'>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={confirmarEnvio}  disabled={ListadoParticipantes.length === 0 && Loading} >
                                        <i className="bi bi-cursor"></i> Enviar Emails
                                </button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-2">
                                        <strong className='label'>Enviados:</strong> <span className='texto'>{totales.enviados}</span>
                        </div>                    
                        <div className="col-md-2">                    
                                        <strong className='label'>Descargados:</strong> <span className='texto'>{totales.descargados}</span>
                        </div>
                        <div className="col-md-2">                
                                        <strong className='label'>Descuentos:</strong> <span className='texto'>{totales.descuentos}</span>
                        </div>                


                        
                    </div>




                    {Loading && 
                    <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
     
                     <div className="table-responsive">
                        <table className="table table-striped  mt-2">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombres</th>
                        {/*         <th scope="col">Telefono</th> */}
                                <th scope="col">Email</th>
                                <th align="center">Enviado</th>
                                <th align="center">Certificado</th>
                                <th align="center">Descuento</th>
                                
                            </tr>
                            </thead>
                            <tbody>
                            {ListadoParticipantes.map((part, index) => 
                            <tr key={part.id_participante || index}>
                                <th scope="row">{index+1}</th>
                                <td>{part.nombres}</td>
                       {/*          <td>{part.telefono}</td> */}
                                <td>{part.email}</td>
                                <td align="center">
                                    {parseInt(part.enviado) === 1 ? (
                                        <span className="badge bg-success">Enviado</span>
                                    ) : (
                                        <span className="badge bg-secondary">Pendiente</span>
                                    )}
                                </td>
                                <td align="center">
                                    {parseInt(part.descargado) === 1 ? (
                                        <span className="badge bg-success">Descargado</span>
                                    ) : (
                                        <span className="badge bg-secondary">No descargado</span>
                                    )}
                                </td>
                                <td align="center">
                                    {parseInt(part.descuento) === 1 ? (
                                        <span className="badge bg-success">Descargado</span>
                                    ) : (
                                        <span className="badge bg-secondary">No descargado</span>
                                    )}
                                </td>
                               
                            </tr>
                            )}    
                            
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
            </section>
            <ToastContainer />
    </>
  )
}

export default EnviarEmail