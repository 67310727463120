import React, { useContext, useEffect, useState } from 'react'
import { NavBar } from '../includes/NavBar'
import SideBar from '../includes/SideBar'
import HomePrincipal from './HomePrincipal'
import { Route, Routes } from 'react-router-dom'
import NotFound from './NotFound'
import config from '../config'
import { SessionContext } from '../SessionContext';
import 'animate.css';


import Seminarios from './Seminarios'
import ImportarArchivo from './ImportarArchivo'

import { useHttpRequest } from '../services/useHttpRequest'
import EnviarEmail from './EnviarEmail'


const HomePage = () => {
    const { token } = useContext(SessionContext); 

    const [DatosEmpresa, setDatosEmpresa] = useState([])
    const [BuscarAlumno, setBuscarAlumno] = useState("")

    const HttpRequest = useHttpRequest();

    const handleToggleSidebar = () => {
      const body = document.body;
      const hasToggleSidebarClass = body.classList.contains('toggle-sidebar');
  
      if (hasToggleSidebarClass) {
        body.classList.remove('toggle-sidebar');
      } else {
        body.classList.add('toggle-sidebar');
      }
    };

    useEffect(() => {
      const fetchEmpresa = async () => {
          if (token) {
              try {
                  const result = await HttpRequest('api/empresa', 'GET');
                  if (result.error === false) {
                      setDatosEmpresa(result.empresa);
                  } else {
                      console.error('Error al obtener los datos de la empresa:', result.message);
                  }
              } catch (error) {
                  console.error('Error en la solicitud:', error);
              }
          }
      };
  
      fetchEmpresa(); // Llama a la función asíncrona
  }, [token]);
  

   
  return (
    <>
        <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between ">
          <a href="/home" className="logo d-flex align-items-center animate__animated animate__flipInY">
            <img src="/assets/img/logo_national.svg" alt="" />
            <span className="d-none d-lg-block">{config.NOMBRE_APP}</span>
          </a>
          <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSidebar}></i>
        </div>

        <div className="search-bar">
          <form className="search-form d-flex align-items-center" method="POST" action="#" onSubmit={(e) => e.preventDefault()} >
            <input type="text" name="query" autoComplete="off" placeholder="Buscar" 
                  title="Ingrese el nombre del alumno a buscar" value={BuscarAlumno} onChange={(e)=> setBuscarAlumno(e.target.value)} />
            <button type="submit" title="Search"><i className="bi bi-search"></i></button>
          </form>

        </div>

        <div className="search-bar">
         <img src={config.LOGOINS} width={30} /><span className='card-title'>{DatosEmpresa.nombre_emp} </span>  
        </div> 

            <NavBar />

        </header>

        {/* *** Sidebar ** */}
            <SideBar />

            <main id="main" className="main">
              <div className='container'>
              <Routes>
                <Route path="/" element={<HomePrincipal />} />
                <Route path="/principal" element={<HomePrincipal />} />
                <Route path="/seminarios" element={<Seminarios />} />
                <Route path="/importar" element={<ImportarArchivo />} />
                <Route path="/participantes" element={<ImportarArchivo />} />
                <Route path="/enviaremail" element={<EnviarEmail />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              </div>
            </main>


        <footer id="footer" className="footer">
        <div className="copyright">
          &copy; Copyright <strong><span>{config.EMPRESA}</span></strong>. All Rights Reserved
        </div>
        <div className="credits">  
          Designed by <a href={config.URL_EMPRESA}>{config.EMPRESA}</a>
        </div>
        </footer>

        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

       

       
    </>
  )
}

export default HomePage