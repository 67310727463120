// config.js

const config = {
    LOGO: '/assets/img/logo.jpg',
    VERSION_APP: 1.5,
    NOMBRE_APP: 'Seminarios National',
    API_URL_PPAL: 'https://national.epikominteractive.com/api/public/',
    API_URL_INFORMES: 'https://national.epikominteractive.com/informe/',
    API_URL_FOTOS: 'https://national.epikominteractive.com/api/public/uploads/fotoperfil/',
    API_URL_PATH: 'https://national.epikominteractive.com',
    EMPRESA: 'Epikom Solucion',
    URL_EMPRESA: '#'
  };
  
  export default config;
  
  
 /*
const config = {
    LOGO: '/assets/img/logo.jpg',
    VERSION_APP: 1.5,
    NOMBRE_APP: 'Seminarios National',
    API_URL_PPAL: 'http://127.0.0.1/api-certificados-nacional/public',
    API_URL_INFORMES: 'https://national.epikominteractive.com/informe/',
    API_URL_FOTOS: 'http://127.0.0.1/api-certificados-nacional/uploads/fotosperfil/',
    API_URL_PATH: 'https://national.epikominteractive.com',
    EMPRESA: 'Epikom Solucion',
    URL_EMPRESA: '#'
  };
  
  export default config;
  */